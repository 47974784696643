body{
    
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 750px;
    /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, 
               "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, 
               "Helvetica Neue", sans-serif;
}

#bj-main-container{
  background-color: hsla(140, 18%, 26%, 0.921);
  margin-top: 10px;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content:center;
  width: 380px;
  height: 600px;
  overflow: hidden; 
  border: 1px ridge #d4af37;
  border-radius: 10px;
  box-shadow: 
  0 0 5px rgba(255, 119, 0, 0.817), 
  0 0 5px rgba(145, 255, 0, 0.5), 
  0 0 5px rgba(255,255,0,0.4);
  transition: transform 0.6s;
  transform-style: preserve-3d;
  
}
.loading-page{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  z-index: 100;
  transition: opacity 4s ease-out;
  opacity: 1;
}

.logo-img{
  position: relative;
  display: inline-block; 
}

.loading{
  position: relative;
  width: 260px;
  height: 12px; 
}
.loading::after{
  top:64%;
  content: ""; 
  position: absolute;
  width: 0%;
  height: 5px; 
  border-radius: 12px;
  background-color: #d4af37;
  animation: loading-animation 6s cubic-bezier(0, .5, 0, 1) infinite;
}
@keyframes loading-animation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;

  }
}
#container {

  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 380px;
  height: 600px;
  overflow: hidden; 
  border: 1px ridge #d4af37;
  border-radius: 10px;
  box-shadow: 
  0 0 5px rgba(255, 119, 0, 0.817), 
  0 0 5px rgba(145, 255, 0, 0.5), 
  0 0 5px rgba(255,255,0,0.4);
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
#gamerecap-container{
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 180px;
  height: 600px;
  overflow: hidden; 
  border: 1px ridge #d4af37;
  
  color: hsla(116, 44%, 7%, 0.99);
  font-size: 8px;
  border-radius: 10px;
  box-shadow: 
  0 0 5px rgba(255, 119, 0, 0.817), 
  0 0 5px rgba(145, 255, 0, 0.5), 
  0 0 5px rgba(255,255,0,0.4);
  transition: transform 0.6s;
  transform-style: preserve-3d;
  overflow: auto;
}
#gamerecap-element,#gamerecap-cell{
  padding: 5px;
  color: hsla(116, 44%, 7%, 0.99);
  border-radius: 10px;
  text-wrap:balance;
  border: 1px solid black;
  width: 100%;
  height: 100%;
  display: flex;
  font-weight: bold;
  
}
#recap-cell{
  font-family:monospace;
  font-weight: 400;
  color: #ece8db;
  width: 100%;
  font-size: 8.9px;
  border-radius: 10px;
  padding: 5px;
  overflow-y: auto;
}

#gamerecap-cell{
  color: #ece8db;
  padding: 5px;
  font-family:monospace;
  font-weight: 400;
  font-size: 8.9px;
}

.logo-img {
  position: flex;
  width: 70%;
  border-radius: 10px;
  opacity: 1;
  animation: glowAnimation 2s infinite;
}
.logo-img::before, .logo-img::after {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit; /* If your image or container has rounded corners */
  z-index: -1; /* Position the pseudo-element below the content */
  animation: glowAnimation 2s infinite;
}
@keyframes glowAnimation {
  0%, 100% {
    box-shadow:
      0 0 5px rgba(255, 119, 0, 0.5), 
      0 0 15px rgba(145, 255, 0, 0.3), 
      0 0 25px rgba(255, 255, 0, 0.2);
  }
  50% {
    box-shadow:
      0 0 20px rgba(255, 119, 0, 0.8), 
      0 0 30px rgba(145, 255, 0, 0.6), 
      0 0 40px rgba(255, 255, 0, 0.4);
  }
}

#blackjack-logo{
  margin-top: 20px;
  width: 300px;
  opacity: 85%;
}

#container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(./images/table.png);
  background-color: hsla(116, 44%, 7%, 0.99);
  background-size: 185%;
  /*background-size: cover;*/
  background-position: center;
  opacity: 0.45; 
  z-index: -1; 
  opacity: var(--bg-opacity, 0.45); /* Use a CSS variable */
  z-index: var(--z-index, -1); 
}
#container.flipped {
  transform: rotateY(180deg);
}
#deck-dealer-row{
display: flex;
justify-content: space-between;
width: 100%;
height: 0px;
}
#discard-tray{
left: 0;
width: 100px;
height: 0px;
}
#new-deck{
right: 0;
width: 100px;
height: 0px;
}
#card-image-right-corner,#card-image-left-corner {
  border: 1px solid hsla(25, 72%, 11%, 0.96);
}

#blackjack-header-container{
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#game-settings{
margin-right: 5px;
border: 1px solid #a47523;
background-color: hsla(25, 72%, 11%, 0.96);
color: aliceblue;
border-radius: 15px;
width: 10%;
font-size: 1rem;
text-align: center;
box-shadow: 0px 0px 10px rgba(31, 13, 13, 0.968);
height: 100%;
cursor: pointer;
}
.chips-count,#games-count,#game-result{
margin-left: 5px;
border: 1px solid #a47523;
background-color: hsla(25, 72%, 11%, 0.96);
color: aliceblue;
border-radius: 15px;
width: 40%;
text-align: center;
box-shadow: 0px 0px 10px rgba(31, 13, 13, 0.968);
align-content: center;
height: 100%;

}
#games-count{
  display: flex;
  align-items: center;
}
#games-count-filler{
  margin-left: 2px;
  margin-right: 2px;
  height:65%;
  background-color: #e4b528;
  border-radius: 15px;
  transition: width 0.5s ease-out;
}

#blackjack-header{
    display: flex;
    width: 210px;
    height: 140px;
    text-align: center;
    color: #a47523;
    font-size: 3.2rem;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight:bolder;
    box-sizing: 1px solid black;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-shadow: 0px 0px 10px rgba(31, 13, 13, 0.99); 
}






#card-image-left-corner{
    margin-left: 5px;
    margin-top: 5px;
    width: 80%;
    /* left corner angle Z 30deg Y -50deg  X 50deg */
       /* right corner angle Z -65deg Y -19deg  X 50deg */
    transform: rotateZ(30deg) rotateY(-50deg) rotateX(50deg);
    transform: perspective();
}
#card-image-right-corner{
  margin-left: 5px;
  margin-top: 5px;
  width: 80%;
  /* left corner angle Z 30deg Y -50deg  X 50deg */
   /* right corner angle Z -65deg Y -19deg  X 50deg */
  transform: rotateZ(-65deg) rotateY(-19deg) rotateX(50deg);
  transform: perspective();
}
#card-image{
    margin-left: 5px;
    margin-top: 5px;
    width: 80%;
  /* left corner angle Z 30deg Y -50deg  X 50deg */
   /* right corner angle Z -65deg Y -19deg  X 50deg */
    transform: rotateZ(30deg) rotateY(-50deg) rotateX(50deg);
    transform: perspective();
    
    /* transform: rotateZ(-5deg); */

 }
#buttons-container{
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    min-height: 85px ;
    margin-bottom: -15px;
}
.bet-chip-img {
  position: absolute;
  transition: transform 0.3s ease;
  transform: rotateZ(90deg) rotateY(60deg);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 0%, 0.65); 
  font-size: 0.75rem;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px 0px 1px hsla(0, 0%, 0%, 0.917);
}

#buttons-row{
    display: flex;
    justify-content: center;
    height: 55px;
    width: 100%;
    gap: 15px;
}

  #chips-row{
    display: flex;
    gap: 8px;
    justify-content: center;

  }

 
  #chips-row button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid hsla(0, 0%, 0%, 0.65); 
    font-size: 0.75rem;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    box-shadow: 0px 4px 6px hsla(0, 0%, 0%, 0.917);
  }
  #chips-row img:hover{
    box-shadow: 0px 4px 6px hsla(0, 0%, 0%, 0.217);
  }
  #bet-main-container{
    display: flex;
    justify-content: center;
    width: 90%;
    height: 10px;
  }
  #bet-container-box {
    display:flex;
    position: relative;
    gap: 8px;
    justify-content: center;
  }
  #bet-container-box img:hover{
    box-shadow: 0px 4px 6px hsla(0, 0%, 0%, 0.67);
  }
  #bet-container-box img:active{
    transform: scale(0.95);
    box-shadow: 0px 4px 6px hsl(159, 59%, 53%);
  }
  
  .chip-img{
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid black;
  }
  #btn-10{
    background-color: hsla(120, 81%, 40%, 0.967);
  }
  #btn-25{
    background-color: hsla(37, 82%, 55%, 0.967);
  }
  #btn-50{
    background-color: hsla(0, 87%, 63%, 0.967);
  }
  #btn-100{
    background-color: hsla(313, 37%, 63%, 0.967);
  }
  #btn-250{
    background-color: hsla(235, 86%, 71%, 0.967);
  }

  #chips-row-wrapper{
    display: flex; 
    justify-content: center;
    gap: 10px;
    min-height: 50px;
    margin-bottom: 1px;
  }
  #chips-row-wrapper img:hover{
    background-color: #a47523;
    box-shadow: 0px 0px 8px 3px #ddb01d; 
  }

.btn-new-game {
    border:0px solid rgba(12, 11, 9, 0.728);
    cursor: pointer;
    width: 75px;
    height: 75px;
    background-color: hsl(160, 10%, 12%);
    border-radius: 50%;
    transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.2s ease; /* Add box-shadow to the transition */
  }
  
  .btn-new-game:hover {
    background-color: #a47523;
    box-shadow: 0px 0px 8px 3px #ddb01d; 
  }
  
  .btn-new-game:active {
    transform: scale(0.95);
    background-color: #ddb01d; 
  }
  .btn-clear-bet{
    border:0px solid rgba(12, 11, 9, 0.728);
    cursor: pointer;
    width: 75px;
    height: 75px;
    background-color: hsl(160, 10%, 12%);
    border-radius: 50%;
    transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.2s ease;
}
.btn-clear-bet:hover {
    background-color:#a47523;
    box-shadow: 0px 0px 8px 3px #ddb01d; 
  }
  
  .btn-clear-bet:active {
    transform: scale(0.95);
    background-color: #a47523;
  }
  .disabled {
    opacity: 0.5; /* Make the button appear faded */
    pointer-events: none; /* Prevent clicking */
  }
  .btn-stand {
    border:0px solid rgba(12, 11, 9, 0.728);
    cursor: pointer;
    width: 75px;
    height: 75px;
    background-color: hsl(160, 10%, 12%);
    border-radius: 50%;
    transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.2s ease;

  }

  .btn-stand:hover{
    background-color: #a47523;
    box-shadow: 0px 0px 8px 3px #ddb01d; 
  
  }
  .btn-stand:active{
    transform: scale(0.95);
    background-color: #a47523;
  }
  .split-btn-stand {
    border:0px solid rgba(12, 11, 9, 0.728);
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: hsl(160, 10%, 12%);
    border-radius: 50%;
    transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.2s ease;

  }

  .split-btn-stand:hover{
    box-shadow: 0px 0px 8px 3px #f44d4d; 
  
  }
  .split-btn-stand:active{
    transform: scale(0.95);
    background-color: #a42347;
  }


  .btn-double{

    border:0px solid rgba(12, 11, 9, 0.728);
    cursor: pointer;
    width: 75px;
    height: 75px;
    background-color: hsl(160, 10%, 12%);
    border-radius: 50%;
    transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.2s ease;
 
  }
  .btn-double:hover{

    box-shadow: 0px 0px 8px 3px #ddb01d; 
  
  }
  .btn-double:active{
    transform: scale(0.95);

  }
  .btn-split{
    border:0px solid rgba(12, 11, 9, 0.728);
    cursor: pointer;
    width: 75px;
    height: 75px;
    background-color: hsl(160, 10%, 12%);
    border-radius: 50%;
    transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.2s ease;
  }
  .btn-split:hover{
    box-shadow: 0px 0px 8px 3px #ddb01d; 
  
  }
  .btn-split:active{
    transform: scale(0.95);
    background-color:#a47523;
  }
  .btn-hit{
    border:0px solid rgba(12, 11, 9, 0.728);
    cursor: pointer;
    width: 75px;
    height: 75px;
    background-color: hsl(160, 10%, 12%);
    border-radius: 50%;
    transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.2s ease;

  }
  .btn-hit:hover{

    box-shadow: 0px 0px 8px 3px #ddb01d; 
  
  }
  .btn-hit:active{
    transform: scale(0.95);
    background-color:#a47523;
  }
  #split-buttons-box{
    display: flex;
    width: 40%;
    justify-content: space-between;
    margin-left: 50px;
    transform: translateY(-5px);
    
  }
  .split-btn-hit{
    border:0px solid rgba(12, 11, 9, 0.728);
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: hsl(160, 10%, 12%);
    border-radius: 50%;
    transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.2s ease;

  }
  .split-btn-hit:hover{
    box-shadow: 0px 0px 8px 3px #66f888; 
  }
  
  .split-btn-hit:active{
    transform: scale(0.95);
    background-color:#25a423;
  }
  
 

  
  #pointer-animation{
    margin-bottom: 30px;
    color: white;
    font-size: 1.3rem;
    text-align:center;
    overflow: visible;
    animation: bobbing 0.7s infinite ease-in-out;
  }
  @keyframes bobbing {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-3px);
    }
  }

  
  .game-area{
    margin-top: 60px;
    position: relative;
    height:100%;
    width: 90%;
    perspective: 1000px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
  }
  #dealer-message-box{
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center;
    align-items: center; /* Center children horizontally */
    min-height: 200px;
    width: 100%;

  }
  #dealerhand-fixedbox {
    width: 100%; /* Adjust this based on how wide you want this box to be */
    min-height: 90px; /* This should be at least as tall as the dealer's hand would be */
    display: flex;
    justify-content: center; /* Center child content horizontally */
    align-items: center; /* Center child content vertically */
    
}
  .message-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  
}
  #message-box{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f5c432;
    min-height: 100px; 
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bolder;
    opacity: 1;
    font-family:serif;
  }
  .message-box-settings{
    display: flex;
    flex-direction: column; /* Ensure children are laid out vertically */
    align-items:flex-start;
    justify-content:flex-start;
    color: #f5c432;
    min-height: 100px; /* Ensure this is enough to show initial items */
    width: 50%;
    font-weight: bolder;
    opacity: 1;
    font-family: serif;
    overflow-y: auto; /* Ensures scrolling for overflow */
    max-height: 2000px; /* Example fixed max-height */
  }
  .combo-text{
    font-family: serif;
    height: auto;
    font-size: 8px;
    color: #fffcf3;
  }

  #hand, #dealer-hand, #player-hand {
    display:flex;
    flex-direction: row; 
    justify-content: center; 
    align-items: center;
    height: 100px;
    position: relative; 
    
  }
  #dealer-hand, #player-hand {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow:visible;
    
  }
  #dealer-hand{
    top: 0px;

  }
  #player-hand{
    bottom: 0px;
  }
  #player-2hands-box{
    display: flex;
    height: 100px;
    justify-content: space-around;
    width: 100%;
    bottom: 0px;
    position: absolute;
  }
#player-hand1{
flex: 1;
position: relative;

}
#player-hand2{
  flex: 1;
  position: relative;

  
}
#player-two-scores-box {
  display: flex;
  justify-content: space-around; /* Adjust as needed based on your design */
  width: 100%;
}


#dealer-score-bubble, #player-score-bubble,#player1-score-bubble,#player2-score-bubble {
  background-color: #d8d5cc;; /* Bubble color */
  color: #000; /* Text color */
  padding: 1px 10px; /* Adjust padding as needed */
  border-radius: 50%; /* Rounded corners for the bubble */
  font-weight: 400;
  visibility: hidden; /* Controlled by your JavaScript */
  position: absolute;
  left: 50%; /* Center the bubble horizontally */
  transform: translateX(-50%); /* Ensure it's centered properly */
}
#dealer-score-bubble {
  top: -30px; /* Position it at the top of the game-area */
}
#score-bubble {
  position: absolute;
  width: 100%; /* Ensure it spans the entire width of the game-area */
  display: flex;
  justify-content: center;
  background-color: #ece8db;
}

#dealer-score-bubble::after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: 0px; /* Adjusted for center alignment */
  border-width: 10px;
  /* Combines translation and rotation into a single transform property */
  transform: translate(-50%, -100%) rotate(180deg); 
  margin-top: 40px;
  border-style: solid;
  border-color: transparent transparent #d8d5cc transparent; /* Arrow pointing up with bubble color */
}

#player-score-bubble::after {
  content: "";
  position: absolute;
  left: 50%; /* Center the pointer under the bubble */
  margin-left: 0px; /* Half the pointer's base width to truly center it */
  border-width: 10px; /* Adjust the size of the pointer */
  
  /* Position the pointer just below the bubble */
  bottom: 22px; /* Adjust based on the bubble's height and desired gap */
  transform: translateX(-50%); /* Ensures accurate horizontal centering */
  
  border-style: solid;
  border-color: transparent transparent #d8d5cc transparent; /* Arrow points up */
}
#player1-score-bubble::after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: 0px; /* Adjusted for center alignment */
  border-width: 10px;
  /* Combines translation and rotation into a single transform property */
  transform: translate(-50%, -100%) rotate(180deg); 
  margin-top: 40px;
  border-style: solid;
  border-color: transparent transparent #d8d5cc transparent; /* Arrow pointing up with bubble color */
}
#player2-score-bubble::after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: 0px; /* Adjusted for center alignment */
  border-width: 10px;
  /* Combines translation and rotation into a single transform property */
  transform: translate(-50%, -100%) rotate(180deg); 
  margin-top: 40px;
  border-style: solid;
  border-color: transparent transparent #d8d5cc transparent; /* Arrow pointing up with bubble color */
}
#player-score-bubble {
  position: absolute;
  bottom: -30px; 
  display: flex;
}
#player1-score-bubble,#player2-score-bubble{
  position: absolute;
  top: -130px; 
  display: flex;
}



  
.card-box{
    perspective: 1000px;
    margin: 0 -15px;
}

.card-content{
  width: 63px;
  height: 90px; 
  transform-style: preserve-3d;
  transition: transform 2s;
}

.flip {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  -webkit-backface-visibility: hidden; /* for Safari 5.1+ and iOS 5+ */
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.card-front {
  transform: rotateY(180deg);
}
.card-content img{
  width: 100%;
  height: 100%;
  border-radius: 7px;
}
#player-hand-box{
  position: relative;
}
.odds-main-box{
  position: absolute;
  top: 0;
  left: 24.4%;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: opacity 4s ease-out;
  opacity: 1;
  width: 367px;
  height: 598px;
  background-color: hsla(140, 18%, 26%, 0.75); 
  border-radius: 10px;
}
.odds-main-box{
  overflow-y: auto;
}
